import React from "react";
import { useGetChargesReportInvoiceDownload } from "api/generated/customer/customer";
import { ErrorAlert } from "components/molecules/ErrorAlert";
import {
  MenuItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import { SxStyleSheet } from "providers/UIProvider";
import { useBanParam } from "hooks/useBanParam";

interface DownloadChargesReportProps {
  invoiceId: number;
  downloadHook: typeof useGetChargesReportInvoiceDownload;
  label: string;
}

export const DownloadMenuItem = ({
  invoiceId,
  downloadHook,
  label,
}: DownloadChargesReportProps) => {
  const ban = useBanParam();
  const { error, isFetching, refetch } = downloadHook(ban, invoiceId, {
    query: {
      enabled: false,
      cacheTime: 0,
      onSuccess: (data) => {
        if (data?.link) window.location.href = data.link;
      },
    },
  });

  if (error) return <ErrorAlert message={error.message} />;

  return (
    <MenuItem disabled={isFetching} onClick={() => refetch()} sx={styles.root}>
      {isFetching ? (
        <ListItemIcon>
          <CircularProgress size={24} />
        </ListItemIcon>
      ) : (
        <ListItemText>{label}</ListItemText>
      )}
    </MenuItem>
  );
};

const styles: SxStyleSheet = {
  root: { "&.Mui-disabled": { opacity: 1 } },
};
