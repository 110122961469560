import React, { Dispatch, SetStateAction } from "react";
import { Box, TableCell } from "@mui/material";
import { MUIDataTableColumn } from "mui-datatables";
import { InvoiceSummary, Page } from "api/generated/schemas";
import { DownloadOptions } from "screens/customer/invoices/DownloadOptions/DownloadOptions";
import { Pagination } from "components/organisms/PaginatedTable/hooks/usePagination";
import { PaginatedTable } from "components/organisms/PaginatedTable/PaginatedTable";
import { SxStyleSheet } from "providers/UIProvider";
import { toCurrency } from "helpers/utils";

interface InvoicesTableProps {
  data?: { _data: InvoiceSummary[]; _page: Page };
  pagination: Pagination;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  isLoading?: boolean;
}

interface column extends MUIDataTableColumn {
  name: keyof InvoiceSummary;
}

const columns: column[] = [
  {
    name: "ban",
    label: "BAN",
  },
  {
    name: "ben",
    label: "BEN",
  },
  {
    name: "invoiceDate",
    label: "Invoice Date",
  },
  {
    name: "invoiceNumber",
    label: "Invoice Number",
  },
  {
    name: "numberOfMpns",
    label: "No. MPNs",
  },
  {
    name: "totalBeforeVat",
    label: "Total Before VAT",
    options: {
      customBodyRender: (val) => toCurrency(val, true),
    },
  },
  {
    name: "vat",
    label: "VAT",
    options: {
      customBodyRender: (val) => toCurrency(val, true),
    },
  },
  {
    name: "total",
    label: "Total",
    options: {
      customBodyRender: (val) => toCurrency(val, true),
    },
  },
  {
    name: "dueDate",
    label: "Due Date",
  },
  {
    name: "billNumber",
    label: "Bill Number",
    options: { display: "false" },
  },
  {
    name: "id",
    label: "Download",
    options: {
      customBodyRender: (value, tableMeta) => {
        console.log(tableMeta);
        return (
          <DownloadOptions
            invoiceId={value}
            billNumber={tableMeta.rowData?.[tableMeta.columnIndex - 1]}
            ben={tableMeta.rowData?.[tableMeta.columnIndex - 9]}
          />
        );
      },
      customHeadRender: ({ name }) => <TableCell key={name} />,
    },
  },
];

export const InvoicesTable = ({
  data,
  pagination,
  setPagination,
  isLoading,
}: InvoicesTableProps) => {
  return (
    <Box sx={styles.root} component="main">
      <PaginatedTable
        data={data}
        columns={columns}
        pagination={pagination}
        setPagination={setPagination}
        isLoading={isLoading}
        options={{ viewColumns: false }}
      />
    </Box>
  );
};

const styles: SxStyleSheet = {
  root: {
    mt: 2,
    width: "100%",
  },
};
